import React, { memo, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from './auth/AuthContext';  // Import the AuthProvider

import "./App.css";
import "./media.css";
import "./Other.css";
import "./english.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "aos/dist/aos.css";
import AOS from "aos";

import CourseList from "./Components/CourseList";
import Course from "./Components/Course";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Navbar from "./Components/Navbar";
import ScrollToTop from "./ScrollToTop";
import Errorpage from "./Components/404/Errorpage";
import About from "./Components/About";
// import Popup from "./Components/popup/Popup";
import Englishmain from "./Components/english/Englishmain";
// import Gallery from "./Components/gallery/Gallery";
// import Admin from "./auth/Admin";  // Import the Admin component

function App() {
  // const [isPopupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 1000,
      easing: "ease",
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <AuthProvider> {/* Wrap the entire app with AuthProvider */}
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/CourseList" element={<CourseList />} />
          <Route path="/Course/:name" element={<Course />} />
          <Route path="/About" element={<About />} />
          {/* <Route path="/gallery" element={<Gallery />} /> */}
          <Route path="/english" element={<Englishmain />} />
          {/* <Route path="/admin" element={<Admin />} />  */}
          <Route path="*" element={<Errorpage />} />
        </Routes>
        <Footer />
        {/* <Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} /> */}
      </BrowserRouter>
    </AuthProvider>
  );
}

export default memo(App);
